import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { SnakeToCamelCasePipe } from './pipes/snakeToCamelCase/snake-to-camel-case-pipe';
import { SnakeToCamelCaseStringPipe } from './pipes/snakeToCamelCaseString/snake-to-camel-case-string-pipe';
import { FormatFilterOptionsPipe } from './pipes/format-filter-options/format-filter-options.pipe';

import { HeaderComponent } from "./components/header/header.component";
import { ProfileButtonComponent } from "./components/profile-button/profile-button.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { AlertMessageComponent } from "./components/alert-message/alert-message.component";
import { ModalComponent } from "./components/modal/modal.component";
import { TableComponent } from './components/table/table.component';

import { DropDownComponent } from './components/drop-down/drop-down.component';
import { InputComponent } from './components/input/input.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerRangeComponent } from './components/date-picker-range/date-picker-range.component';
import { StatusStepperComponent} from '../core/components/status-stepper/status-stepper.component';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { HotelCardComponent } from './components/hotel-card/hotel-card.component';
import { CarRentalCardComponent } from './components/car-rental-card/car-rental-card.component';
import { HotelRoomCardComponent } from './components/hotel-room-card/hotel-room-card.component';

@NgModule({
  declarations: [
    SnakeToCamelCasePipe,
    SnakeToCamelCaseStringPipe,
    FormatFilterOptionsPipe,
    HeaderComponent,
    ProfileButtonComponent,
    LoaderComponent,
    AlertMessageComponent,
    ModalComponent,
    TableComponent,
    DropDownComponent,
    InputComponent,
    DatePickerComponent,
    DatePickerRangeComponent,
    StatusStepperComponent,
    FlightCardComponent,
    HotelCardComponent,
    CarRentalCardComponent,
    HotelRoomCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    BsDatepickerModule,
    SnakeToCamelCasePipe,
    SnakeToCamelCaseStringPipe,
    FormatFilterOptionsPipe,
    HeaderComponent,
    ProfileButtonComponent,
    LoaderComponent,
    AlertMessageComponent,
    ModalComponent,
    TableComponent,
    DropDownComponent,
    InputComponent,
    DatePickerComponent,
    DatePickerRangeComponent,
    StatusStepperComponent,
    HotelCardComponent,
    CarRentalCardComponent,
    FlightCardComponent,
    HotelRoomCardComponent
  ],
  providers: [
    SnakeToCamelCasePipe,
    SnakeToCamelCaseStringPipe,
    FormatFilterOptionsPipe,
  ],
})
export class CoreModule { }
