<div class="row">
  <ng-container *ngFor="let step of stepper">
    <div class="col-sm-2 justify-content-center step-margin">
      <div class="justify-content-center stepper-text" [ngClass]="step.icon">
        <li class="nav-item letter-div"></li>
      </div>
      <div class="justify-content-center stepper-text" [ngClass]="step.status">
        {{ step.name }}
      </div>
    </div>
  </ng-container>
</div>
