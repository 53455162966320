import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlightOptions } from 'src/app/interfaces/flight-options';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: 'app-flight-lists',
  templateUrl: './flight-lists.component.html',
  styleUrls: ['./flight-lists.component.scss'],
})
export class FlightListsComponent implements OnInit {
  status: boolean = true;
  bookingList = 'col-sm-12';
  bookingConfirm = '';
  sideTabs = [
    { name: 'FLIGHT', active: false },
    { name: 'FARE BREAKDOWN', active: false },
  ];
  selectedFlight: FlightOptions = null;

  public flights: FlightOptions[] = [
    {
      image: '../../../../../assets/images/flights/airlink.png',
      arrivalDate: '2024-12-11',
      arrivalTime: '10:00',
      departDate: '2024-12-10',
      departTime: '10:00',
      departAirport: 'JHB',
      arrivalAirport: 'CPT',
      flightNo: 'CA51251',
      price: 2259,
      flightDuration: '2h 15m',
      numberOfStops: 2,
      flightType: 'Business',
    },
    {
      image: '../../../../../assets/images/flights/british.png',
      arrivalDate: '2024-12-11',
      arrivalTime: '10:00',
      departDate: '2024-12-10',
      departTime: '10:00',
      departAirport: 'JHB',
      arrivalAirport: 'CPT',
      flightNo: 'CA51251',
      price: 2259,
      flightDuration: '2h 15m',
      numberOfStops: 2,
      flightType: 'Business',
    },
    {
      image: '../../../../../assets/images/flights/emirates.png',
      arrivalDate: '2024-12-11',
      arrivalTime: '10:00',
      departDate: '2024-12-10',
      departTime: '10:00',
      departAirport: 'JHB',
      arrivalAirport: 'CPT',
      flightNo: 'CA51251',
      price: 2259,
      flightDuration: '2h 15m',
      numberOfStops: 2,
      flightType: 'Business',
    },
    {
      image: '../../../../../assets/images/flights/saa.png',
      arrivalDate: '2024-12-11',
      arrivalTime: '10:00',
      departDate: '2024-12-10',
      departTime: '10:00',
      departAirport: 'JHB',
      arrivalAirport: 'CPT',
      flightNo: 'CA51251',
      price: 2259,
      flightDuration: '2h 15m',
      numberOfStops: 2,
      flightType: 'Business',
    },
    {
      image: '../../../../../assets/images/flights/safair.png',
      arrivalDate: '2024-12-11',
      arrivalTime: '10:00',
      departDate: '2024-12-10',
      departTime: '10:00',
      departAirport: 'JHB',
      arrivalAirport: 'CPT',
      flightNo: 'CA51251',
      price: 2259,
      flightDuration: '2h 15m',
      numberOfStops: 2,
      flightType: 'Business',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  clickEvent() {
    this.status = !this.status;
  }

  goBack() {
    this.router.navigate([AppRoutes.FLIGHT]);
  }

  flightReceived(flight) {
    this.sideTabs[0].active = true;
    this.bookingList = 'col-sm-9';
    this.bookingConfirm = 'col-sm-3';
    this.selectedFlight = flight;
  }

  setActive(name: string) {
    this.sideTabs.forEach((element: any) => {
      element.active = element.name === name;
    })
  }
}
