import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotelRoomOptions } from 'src/app/interfaces/hotel-room-options';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: 'app-hotel-room-list',
  templateUrl: './hotel-room-list.component.html',
  styleUrls: ['./hotel-room-list.component.scss']
})
export class HotelRoomListComponent implements OnInit {
  status: boolean = true;
  public rooms: HotelRoomOptions[] = [
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'Room 1',
      description: 'This is a description',
      price: 5123,
      ammenities: [{name: 'WIFI'}, {name: 'Pool'}, {name: 'Sea view'}],
    },
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'Room 2',
      description: 'This is a description',
      price: 5123,
      ammenities: [{name: 'WIFI'}, {name: 'Pool'}, {name: 'Sea view'}],
    },
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'Room 3',
      description: 'This is a description',
      price: 5123,
      ammenities: [{name: 'WIFI'}, {name: 'Pool'}, {name: 'Sea view'}],
    },
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'Room 4',
      description: 'This is a description',
      price: 5123,
      ammenities: [{name: 'WIFI'}, {name: 'Pool'}, {name: 'Sea view'}],
    },
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'Room 5',
      description: 'This is a description',
      price: 5123,
      ammenities: [{name: 'WIFI'}, {name: 'Pool'}, {name: 'Sea view'}],
    },
  ];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goBack() {
    this.router.navigate([AppRoutes.HOTEL_LIST]);
  }

  clickEvent() {
    this.status = !this.status;
  }
}
