import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlightOptions } from 'src/app/interfaces/flight-options';

@Component({
  selector: 'custom-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss']
})
export class FlightCardComponent implements OnInit {
  @Output() flightSelection = new EventEmitter<string>();
  @Input('flight') flight: FlightOptions;

  constructor() { }

  ngOnInit(): void {
  }

  flightClick() {
    this.flightSelection.emit('');
  }

}
