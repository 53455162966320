<div class="row hero">
  <div class="col-sm-12">
    <div class="section-container">
      <div class="row">
        <div class="col-sm-12">
          <status-stepper></status-stepper>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="header">{{reasonForTravel ? 'Reason for Travel' : 'Who is travelling?'}}</div>
      </div>

      <div class="traveler row d-flex justify-content-center mt-5">
        <div class="traveler">Traveler 1</div>
      </div>

      <div class="row d-flex mx-3 mt-5" *ngIf="!reasonForTravel">
        <div class="col-sm-4">
          <div class="btn left-button" (click)="changeView('bookForMyself')">
            <div class="button-text">BOOK FOR MYSELF</div>
          </div>
          <div class="btn left-button" (click)="changeView('search')">
            <div class="button-text">SEARCH FOR A TRAVELER</div>
          </div>
          <div class="btn left-button" (click)="changeView('addGuest')">
            <div class="button-text">ADD GUEST</div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="travelerTitle">{{ headerTitle }}</div>

          <div *ngIf="buttonSelected.bookForMyself">
            <div class="row d-flex mt-4">
              <div class="col-sm-6">
                <div class="input-title mb-2">Number of travellers</div>
                <custom-dropdown
                  [options]="passengerOption"
                  [width]="'100%'"
                  [placeholder]="passengerOption[0].value"
                  [adjustable]="true"
                  (returnSelectionChanged)="returnChanged($event)"
                >
                </custom-dropdown>
              </div>
              <div class="col-sm-6">
                <div class="input-title mb-2">Title</div>
                <custom-dropdown
                  [options]="titleOptions"
                  [width]="'100%'"
                  [placeholder]="titleOptions[0].value"
                  (returnSelectionChanged)="returnChanged($event)"
                >
                </custom-dropdown>
              </div>
            </div>

            <div class="row d-flex mt-4">
              <div class="col-sm-6">
                <div class="input-title mb-2">First Name</div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    placeholder="First Name"
                    class="form-control input-text"
                    aria-label="First Name"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-title mb-2">Last Name</div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="form-control input-text"
                    aria-label="Last Name"
                  />
                </div>
              </div>
            </div>

            <div class="row d-flex mt-4">
              <div class="col-sm-6">
                <div class="input-title mb-2">Email Address</div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    placeholder="Email Address"
                    class="form-control input-text"
                    aria-label="Email Address"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="input-title mb-2">Date of Birth</div>
                <date-picker></date-picker>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-5">
              <button type="button" class="btn btn-primary right-button" (click)="continuePressed()">
                <div class="row justify-content-center form-inline">
                  <div class="button-text">CONTINUE</div>
                  <i
                    class="fa fa-long-arrow-right icon pl-2"
                    aria-hidden="true"
                  ></i>
                </div>
              </button>
            </div>
          </div>

          <div *ngIf="buttonSelected.search">
            <div class="row d-flex mt-4 additional-margin">
              <div class="col-sm-6">
                <custom-input
                [options]="nameOptions"
                [placeholder]="nameOptions[0].value"
                [width]="'100%'"
                [icon]="'fa-solid fa-magnifying-glass'"
              ></custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="reasonForTravel">
        <div class="row d-flex mt-4 additional-margin justify-content-center">
          <div class="col-sm-6">
            <custom-input
            [options]="travelReasons"
            [placeholder]="travelReasons[0].value"
            [width]="'100%'"
            [icon]=""
          ></custom-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
