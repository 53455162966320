import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { Options } from "src/app/interfaces/options";

@Component({
  selector: "app-traveler",
  templateUrl: "./traveler.component.html",
  styleUrls: ["./traveler.component.scss"]
})
export class TravelerComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();
  headerTitle: string = 'BOOK FOR MYSELF';
  buttonSelected = {
    bookForMyself: true,
    search: false,
    addGuest: false
  }
  reasonForTravel: boolean = false;

  public passengerOption: Options[] = [
    {
      value: 'Adult',
      subtitle: '(+16 yrs)',
      quantity: 0,
    },
    {
      value: 'Young Adult',
      subtitle: '(12-15 yrs)',
      quantity: 0,
    },
    {
      value: 'Children',
      subtitle: '(2-11 yrs)',
      quantity: 0,
    },
    {
      value: 'Infants',
      subtitle: '(under 2 yrs)',
      quantity: 0,
    }
  ];
  public titleOptions: Options[] = [
    {
      value: 'Mr',
    },
    { value: 'Ms' },
  ];

  public nameOptions: Options[] = [{
    value: 'JP Smith'
  }, { value: 'Passanger 2' }, { value: 'Passanger 3' }];

  public travelReasons: Options[] = [{
    value: 'Reason 1'
  }, { value: 'Reason 2' }, { value: 'Reason 3' }];


  constructor(

  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  returnChanged(event: String) {
    console.log(event);
  }

  changeView(type: String) {
    this.buttonSelected.addGuest = type === 'addGuest';
    this.buttonSelected.bookForMyself = type === 'bookForMyself';
    this.buttonSelected.search = type === 'search';
    
    this.headerTitle = this.buttonSelected.bookForMyself ? 'BOOK FOR MYSELF' : 'SEARCH FOR TRAVELLER';
  }

  continuePressed() {
    this.reasonForTravel = !this.reasonForTravel;
  }
}
