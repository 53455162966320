import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotelOptions } from 'src/app/interfaces/hotel-options';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: 'app-hotel-lists',
  templateUrl: './hotel-lists.component.html',
  styleUrls: ['./hotel-lists.component.scss'],
})
export class HotelListsComponent implements OnInit {
  status: boolean = true;
  public hotels: HotelOptions[] = [
    {
      image: '../../../../../assets/images/hotels/hotel-1.png',
      name: 'THE PRESIDENT HOTEL',
      rating: '8.4 / 10',
      pricePerNight: 596,
      checkInDate: 'Fri, 22 Dec 2023 ',
      checkOutDate: 'Thurs, 28 Dec 2023',
      number: '(27) 021 123 4567',
      email: 'bookings@presidenthotel.com',
      price: 5123,
    },
    {
      image: '../../../../../assets/images/hotels/hotel-2.png',
      name: 'THE VINEYARD',
      rating: '8.4 / 10',
      pricePerNight: 596,
      checkInDate: 'Fri, 22 Dec 2023 ',
      checkOutDate: 'Thurs, 28 Dec 2023',
      number: '(27) 021 123 4567',
      email: 'bookings@presidenthotel.com',
      price: 5123,
    },
    {
      image: '../../../../../assets/images/hotels/hotel-3.png',
      name: 'MOJO HOTEL SEA POINT',
      rating: '8.4 / 10',
      pricePerNight: 596,
      checkInDate: 'Fri, 22 Dec 2023 ',
      checkOutDate: 'Thurs, 28 Dec 2023',
      number: '(27) 021 123 4567',
      email: 'bookings@presidenthotel.com',
      price: 5123,
    },
    {
      image: '../../../../../assets/images/hotels/hotel-4.png',
      name: 'O’ TWO HOTEL',
      rating: '8.4 / 10',
      pricePerNight: 596,
      checkInDate: 'Fri, 22 Dec 2023 ',
      checkOutDate: 'Thurs, 28 Dec 2023',
      number: '(27) 021 123 4567',
      email: 'bookings@presidenthotel.com',
      price: 5123,
    },
    {
      image: '../../../../../assets/images/hotels/hotel-5.png',
      name: 'RADISSON BLU HOTEL WATERFRONT',
      rating: '8.4 / 10',
      pricePerNight: 596,
      checkInDate: 'Fri, 22 Dec 2023 ',
      checkOutDate: 'Thurs, 28 Dec 2023',
      number: '(27) 021 123 4567',
      email: 'bookings@presidenthotel.com',
      price: 5123,
    },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {}

  clickEvent() {
    this.status = !this.status;
  }

  goBack() {
    this.router.navigate([AppRoutes.HOTEL]);
  }

  viewHotelRooms(hotel: any) {
    //selected hotel where id will be pulled and passed through params to hotel-room-list
    this.router.navigate([AppRoutes.HOTEL_ROOM_LIST]);
  }
}
