import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/store';
// import { getAuthorisedNavItems } from 'src/app/store/core-store/selectors/navigation.selector';
import { NavItem } from 'src/app/models/NavItem'
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { HeaderService } from 'src/app/services/header.service';
import { navItems } from "../../../app.const";

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  activeRoute = 'dashboard';
  logo = '../../../../assets/images/logo.png';
  navItems: NavItem[];

  constructor(
    private router: Router,
    private store: Store<RootStoreState.RootState>,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.navItems = navItems;
    // this.fetchNavigationItems();
    // this.headerService.setAuthorizedNavItems();
  }

  fetchNavigationItems() {
    this.headerService.currentNavItems$.subscribe(navItems => {
      this.navItems = navItems;
    })
  }

  getActiveRoute() {
    return this.activeRoute;
  }

  setActiveTab(tab) {
    this.activeRoute = tab;
    this.router.navigate([this.activeRoute]);
  }

  navigateUsers() {
    debugger
    this.router.navigate([AppRoutes.USER_LIST]);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  home() {
    this.router.navigate([AppRoutes.FLIGHT]);
  }

}
