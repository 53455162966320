import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { Options } from "src/app/interfaces/options";

@Component({
  selector: "app-flight",
  templateUrl: "./flight.component.html",
  styleUrls: ["./flight.component.scss"]
})
export class FlightComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();

  public returnList: Options[] = [{
    value: 'OR Tambo'
  }, { value: 'King Shaka' }, { value: 'Port Elizabeth' }];

  public flightOption: Options[] = [{
    value: 'Economy'
  }, { value: 'Economy +' }, { value: 'Business' }];

  public passengerOption: Options[] = [
    {
      value: 'Adult',
      subtitle: '(+16 yrs)',
      quantity: 0,
    },
    {
      value: 'Young Adult',
      subtitle: '(12-15 yrs)',
      quantity: 0,
    },
    {
      value: 'Children',
      subtitle: '(2-11 yrs)',
      quantity: 0,
    },
    {
      value: 'Infants',
      subtitle: '(under 2 yrs)',
      quantity: 0,
    }
  ];

  public airportOptions: Options[] = [
    { value: 'OR Tambo' }, { value: 'King Shaka' }, { value: 'Port Elizabeth' }
  ];

  constructor(

    private router: Router
  ) {
  }

  ngOnInit() {

  }

  returnChanged(selectedReturn: String) {
    console.log(selectedReturn);
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  viewFlightList() {
    this.router.navigate([AppRoutes.FLIGHT_LIST]);
  }
}
