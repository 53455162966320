import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { Options } from "src/app/interfaces/options";

@Component({
  selector: "app-car-rental",
  templateUrl: "./car-rental.component.html",
  styleUrls: ["./car-rental.component.scss"]
})
export class CarRentalComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();
  public pikcupList: Options[] = [{
    value: 'OR Tambo'
  }, {value: 'King Shaka'}, {value: 'Port Elizabeth'}];

  public timeList: Options[] = [
    {value: '06:00 - 07:00'}, {value: '07:00 - 08:00'}, {value: '08:00 - 09:00'}
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  returnChanged(selectedReturn: String) {
    console.log(selectedReturn);
  }

  viewCarList() {
    this.router.navigate([AppRoutes.CAR_RENTAL_LIST]);
  }
}
