import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HotelComponent } from './hotel.component';
import { HotelListsComponent } from './hotel-lists/hotel-lists.component';
import { HotelRoomListComponent } from './hotel-room-list/hotel-room-list.component';

@NgModule({
  declarations: [
    HotelComponent,
    HotelListsComponent,
    HotelRoomListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule
  ],
  exports: [
  ]
})

export class HotelModule { }
