export class EndPoints {
  // Authentication
  public static readonly LOGIN = 'portal/accounts/login/';
  public static readonly CURRENT_USER = 'portal/accounts/me/';

  public static readonly UPDATE = 'api/users/update';
  public static readonly USER_TYPES = '/api/user-types';
  public static readonly USER_ACCESS_TYPES = 'api/users/access-types';

  public static readonly REGISTER = 'api/users/register';
  public static readonly FORGOT_PASSWORD = 'api/users/password/forgot';
  public static readonly RESET_PASSWORD = 'api/users/password/reset';
}

export class AppRoutes {
  public static readonly LOGIN = 'login';
  public static readonly REGISTER = 'register';
  public static readonly FORGOT_PASSWORD = 'accounts/password_reset/';

  public static readonly LANDING = "landing";
  public static readonly DASHBOARD = '/';
  public static readonly USERS = 'users';
  public static readonly USER_LIST = 'user-list';
  public static readonly USER = 'user';
  public static readonly APP = 'app';
  public static readonly APPROVALS = 'approvals';
  public static readonly BOOKINGS = 'bookings';
  public static readonly CAR_RENTAL = 'car-rental';
  public static readonly CAR_RENTAL_LIST = 'car-rental-lists';
  public static readonly FLIGHT = 'flight';
  public static readonly FLIGHT_LIST = 'flight-lists';
  public static readonly HOME = 'home';
  public static readonly HOTEL = 'hotel';
  public static readonly HOTEL_LIST = 'hotel-lists';
  public static readonly HOTEL_ROOM_LIST = 'hotel-room-lists';
  public static readonly TRAVELER = 'traveler';
}
