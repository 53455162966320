<section class="container">
  <div class="row justify-content-center mt-5">
    <div class="col-sm-5 col-5-containter">
      <div *ngIf="showAlert">
        <alert-message [type]="error" [message]="errorMessage"></alert-message>
      </div>
      <div *ngIf="showSuccess">
        <alert-message [errorType]="'success'" [message]="successMessage"></alert-message>
      </div>

      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <label for="email">Email </label>
          <input type="email" class="form-control form-control-lg form-input-h" formControlName="email" id="email">
          <span class="error"
            *ngIf="forgotPasswordForm.get('email').errors?.required && forgotPasswordForm.get('email').touched">Email
            is required</span>
          <span class="error"
            *ngIf="forgotPasswordForm.get('email').touched && !forgotPasswordForm.get('email').errors?.required && forgotPasswordForm.get('email').errors?.email">Email
            is invalid</span>
        </div>

        <div class="form-group d-flex justify-content-center">
          <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="!forgotPasswordForm.valid">
            <span>Reset</span>
          </button>
        </div>

        <div class="form-inline form-group d-flex justify-content-center">
          <label class="control-label">
            <a href="javascript: void(0);" class="form-links" (click)="onCancel()">
              <span>Cancel</span>
            </a>
          </label>
        </div>

      </form>
    </div>
  </div>
</section>