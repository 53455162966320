import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();

  constructor(

  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }
}
