<div class="row hero">
  <div class="col-sm-12">
    <div class="section-container">
      <div class="col-sm-12">
        <status-stepper></status-stepper>
      </div>
      <div class="mx-3 row d-flex">
        <div class="col-sm-12">
          <div class="header mt-4">Hotel</div>
          <div class="subtitle mb-4 pt-1">Where would you like to stay?</div>
          <div class="row d-flex">
            <div class="col-sm-6">
              <div class="title mb-2">Destination</div>
              <custom-input
                [options]="destinationList"
                [placeholder]="destinationList[0].value"
                [width]="'100%'"
                [icon]="'fa-calendar-check-o'"
              ></custom-input>
            </div>
            <div class="col-sm-3">
              <div class="title mb-2">Check In</div>
              <date-picker></date-picker>
            </div>
            <div class="col-sm-3">
              <div class="title mb-2">Check Out</div>
              <date-picker></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex mt-5 mx-3">
        <div class="col-sm-3">
          <div class="title mb-2">Rooms</div>
          <custom-dropdown
            [options]="roomsList"
            [width]="'100%'"
            [placeholder]="roomsList[0].value"
            [adjustable]="true"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
        <div class="col-sm-3">
          <div class="title mb-2">Guests</div>
          <custom-dropdown
            [options]="guestList"
            [width]="'100%'"
            [placeholder]="guestList[0].value"
            [adjustable]="true"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
        <div class="col-sm-3">
          <div class="title mb-2">Radius (km)</div>
          <custom-dropdown
            [options]="radiusList"
            [width]="'100%'"
            [placeholder]="radiusList[0].value"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 my-5 mx-4 flex-column">
          <div class="btn-group-vertical">
            <button type="button" class="btn btn-primary search-button" (click)="viewHotelList()">
              Search Hotels
              <i class="fa fa-long-arrow-right icon" aria-hidden="true"></i>
            </button>

            <button
              type="button"
              class="btn btn-outline-primary skip-button mt-3"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
