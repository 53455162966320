import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarOptions } from 'src/app/interfaces/car-options';

@Component({
  selector: 'custom-car-card',
  templateUrl: './car-rental-card.component.html',
  styleUrls: ['./car-rental-card.component.scss']
})
export class CarRentalCardComponent implements OnInit {
  @Input('car') rentalCar: CarOptions;
  @Output() carSelection = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  confirmCar(hotel: any) {
    this.carSelection.emit(hotel);
  }

}
