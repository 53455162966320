<form #inputForm="ngForm">
    <div class="">
      <div class="input-group mb-2" [ngStyle]="{'width': width}" (click)="toggleDropdown()">
        <div class="input-group-prepend" *ngIf="icon.length > 0">
          <div class="input-group-text white-bg">
            <i class="fa {{icon}}" aria-hidden="true"></i>
          </div>
        </div>
        <input
          name="dropdown"
          [ngClass]="icon.length > 0 ? 'input-no-border' : 'input-border'"
          class="form-control dropdown-container input dropdown-text"
          placeholder="{{placeholder}}"
          type="text"
          [(ngModel)]="dropdown"
          (ngModelChange)="changeInput($event)"
          (blur)="closeDropdown()"
        />
      </div>
    </div>
  
    <ul class="list-group" [ngStyle]="{'width': width}" *ngIf="isDropDownOpen" (mouseleave)="closeDropdown()">
        <li
        [ngClass]="item.isActive ? 'activeOption' : ''"
        (click)="selectOption($event, index)"
        *ngFor="let item of options; let index = index"
        class="list-group-item"
      >
      <div class="dropdown-option">{{ item.value }}</div>
      </li>
    </ul>
  </form>