import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MainRoutingModule } from './main-routing.module';

/** MAIN Modules */
import { ApprovalsModule } from './approvals/approvals.module';
import { BookingsModule } from './bookings/bookings.module';
import { CarRentalModule } from './car-rental/car-rental.module';
import { FlightModule } from './flight/flight.module';
import { HomeModule } from './home/home.module';
import { HotelModule } from './hotel/hotel.module';
import { TravelerModule } from './traveler/traveler.module';
import { UsersModule } from './users/users.module';

/** MAIN Components */
import { LandingComponent } from './landing/landing.component';
import { CommonModule } from '@angular/common';

const providers = [];

@NgModule({
  declarations: [
    LandingComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MainRoutingModule,
    NgbModule,
    CoreModule,
    ApprovalsModule,
    BookingsModule,
    CarRentalModule,
    FlightModule,
    HomeModule,
    HotelModule,
    TravelerModule,
    UsersModule
  ],
  exports: [
  ]
})
export class MainModule {
  static forRoot(): any[] | import("@angular/core").Type<any> | ModuleWithProviders<{}> {
    throw new Error("Method not implemented.");
  }
}

@NgModule({})
export class AfroModule {
  static forRoot(): ModuleWithProviders<AfroModule> {
    return {
      ngModule: MainModule,
      providers,
    };
  }
}

