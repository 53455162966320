<div class="mt-3">
  <div class="row form-inline align-items-center">
    <div class="col-sm-10" style="cursor: pointer;" (click)="goBack()">
      <div class="row form-inline">
        <i class="fa fa-long-arrow-left pr-2 ml-3" aria-hidden="true"></i>
        <div class="title">All Users</div>
      </div>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-primary button">
        <div class="row justify-content-center form-inline">
          <i class="fa fa-plus pr-2" aria-hidden="true"></i>
          <div class="button-text">ADD USER</div>
        </div>
      </button>
    </div>
  </div>

  <div class="row form-inline align-items-center border-top">
    <div class="col-sm-10">
      <span class="title" *ngIf="newUser">Add A New User</span>
      <span class="title" *ngIf="!newUser">James Greer</span>
    </div>
    <div class="col-sm-2" *ngIf="!newUser">
      <button type="button" class="remove-button">
        <div class="row justify-content-center form-inline">
          <div class="remove-button-text">DEACTIVATE</div>
        </div>
      </button>
    </div>
  </div>

  <div class="row align-items-center section-margin">
    <div class="col-sm-12 mb-5">
      <div class="blue-title">Personal Information</div>

      <div class="row d-flex">
        <div class="col-sm-3">
          <div class="input-title mb-2">Title</div>
          <custom-dropdown
            [options]="titleOptions"
            [width]="'80%'"
            [placeholder]="'Return'"
            (returnSelectionChanged)="dropdownChanged($event)"
          >
          </custom-dropdown>
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">First Name</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Middle Name</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Last Name</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>
      </div>

      <div class="row d-flex section-margin">
        <div class="col-sm-3">
          <div class="input-title mb-2">Title</div>
          <custom-dropdown
            [options]="genderOptions"
            [width]="'80%'"
            [placeholder]="'Male'"
            (returnSelectionChanged)="dropdownChanged($event)"
          >
          </custom-dropdown>
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Nationality</div>
          <custom-dropdown
            [options]="nationality"
            [width]="'80%'"
            [placeholder]="'South African'"
            (returnSelectionChanged)="dropdownChanged($event)"
          >
          </custom-dropdown>
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Date Of Birth</div>
          <date-picker></date-picker>
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">ID/Passport Number</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder=""
            type="text"
          />
        </div>
      </div>

      <div class="row d-flex section-margin">
        <div class="col-sm-3">
          <div class="input-title mb-2">Role Type</div>
          <custom-dropdown
            [options]="genderOptions"
            [width]="'80%'"
            [placeholder]="'Role'"
            (returnSelectionChanged)="dropdownChanged($event)"
          >
          </custom-dropdown>
        </div>
      </div>

      <div class="blue-title section-additional-margin">
        Contact Information
      </div>
      <div class="row d-flex">
        <div class="col-sm-3">
          <div class="input-title mb-2">Phone Number</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Email Address</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Email type</div>
          <custom-dropdown
            [options]="titleOptions"
            [width]="'80%'"
            [placeholder]="'Return'"
            (returnSelectionChanged)="dropdownChanged($event)"
          >
          </custom-dropdown>
        </div>
      </div>

      <div class="blue-title section-additional-margin">
        Address Information
      </div>
      <div class="row d-flex">
        <div class="col-sm-3">
          <div class="input-title mb-2">Area Code</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Country Code</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Number</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>

        <div class="col-sm-3">
          <div class="input-title mb-2">Location</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>
      </div>

      <div class="row d-flex">
        <div class="col-sm-3 mt-4">
          <div class="input-title mb-2">Type</div>
          <input
            name="dropdown"
            class="form-control dropdown-container input"
            placeholder="James"
            type="text"
          />
        </div>
      </div>

      <div class="col-sm-2 section-additional-margin">
        <button type="button" class="btn btn-primary button">
          <div class="row justify-content-center form-inline">
            <i class="fa fa-plus pr-2" aria-hidden="true"></i>
            <div class="button-text">ADD USER</div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
