<div class="">
  <div class="input-group mb-2">
    <div class="input-group-prepend">
      <div class="input-group-text white-bg">
        <i class="bi bi-calendar3" aria-hidden="true"></i>
      </div>
    </div>
    <input
      type="text"
      placeholder="Daterangepicker"
      class="form-control input-no-border"
      bsDaterangepicker
      [(ngModel)]="bsRangeValue"
      [bsConfig]="{ isAnimated: true }"
    />
  </div>
</div>
