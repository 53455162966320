import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlightComponent } from './flight.component';
import { DropDownComponent } from 'src/app/core/components/drop-down/drop-down.component';
import { FlightListsComponent } from './flight-lists/flight-lists.component';

@NgModule({
  declarations: [
    FlightComponent,
    FlightListsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
  ],
  exports: [
  ]
})

export class FlightModule { }
