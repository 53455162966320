<div
  class="row mb-5 border px-2 py-2 pr-4 d-flex align-content-center justify-content-between"
>
  <div class="row form-inline pl-3 py-2">
    <img class="room-image" src="{{ room.image }}" />
    <div class="flex-column pl-2">
        <div class="room-name pt-1">{{room.name}}</div>
        <div class="room-description">{{room.description}}</div>
        <div class="row form-inline pl-3 pt-3">
            <div class="room-ammenity-label">Ammenities</div>
        </div>
        <div class="row form-inline pl-3 mt-2">
            <div *ngFor="let ammenity of room.ammenities">
                <div class="room-ammenity mr-5">{{ammenity.name}}</div>
            </div>
        </div>
    </div>
  </div>

  <div class="row form-inline">
    <div class="col">
        <div class="room-price">R {{room.price}}</div>
    </div>
    <button type="button" class="btn-primary buy-button mt-2 mx-3">
      <div class="col">
        <div class="button-top-text">BOOK</div>
        <div class="button-bottom-text">NOW</div>
      </div>
    </button>
  </div>
</div>
