<section class="container">
  <div class="row justify-content-center mt-5">
    <div class="col-sm-5 col-5-containter">
      <div *ngIf="showAlert">
        <alert-message [type]="error" [message]="errorMessage"></alert-message>
      </div>
      <div *ngIf="showSuccess">
        <alert-message [errorType]="'success'" [message]="successMessage"></alert-message>
      </div>

      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" class="form-control form-control-lg form-input-h" formControlName="password"
                id="password">
              <span class="error"
                *ngIf="resetPasswordForm.get('password').errors?.required && resetPasswordForm.get('password').touched">Password
                is required</span>
              <span class="error" *ngIf="resetPasswordForm.get('password').hasError('minlength')">
                Password should be atleast 6 characters</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="confirmPassword">Confirm password</label>
              <input type="password" class="form-control form-control-lg form-input-h" formControlName="confirmPassword"
                id="confirmPassword" (change)="validatePasswordMatch()">
              <span class="error"
                *ngIf="resetPasswordForm.get('confirmPassword').errors?.required && resetPasswordForm.get('confirmPassword').touched">
                Confirm password is required</span>
              <span class="error" *ngIf="matchPasswordError">
                Passwords do not match</span>
            </div>
          </div>
        </div>

        <div class="form-group d-flex justify-content-center">
          <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="!resetPasswordForm.valid">
            <span>Reset</span>
          </button>
        </div>

      </form>
    </div>
  </div>
</section>