import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { LoggedInGuardService } from './services/logged-in-guard.service';
import { AppRoutes } from './shared/endpoints.enum';
import { RegisterComponent } from './components/auth/register/register.component';
import { AfroModule } from '../app/components/main/main.module';

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    component: LoginComponent,
    // canActivate: [LoggedInGuardService],
  },
  {
    path: AppRoutes.REGISTER,
    component: RegisterComponent,
    // canActivate: [LoggedInGuardService],
  },
  {
    path: '',
    loadChildren: () =>
      import('../app/components/main/main.module').then(
        (m) => m.AfroModule
      ),
  },
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AfroModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { }
