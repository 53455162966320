'use strict';

import { AppRoutes } from './shared/endpoints.enum';
import { NavItem } from 'src/app/models/NavItem';

export const flight = AppRoutes.FLIGHT;
export const hotel = AppRoutes.HOTEL;
export const car_rental = AppRoutes.CAR_RENTAL;
export const bookings = AppRoutes.BOOKINGS;
export const approvals = AppRoutes.APPROVALS;

export const permissionCustomer = 'customer';
export const permissionStaff = 'staff';
export const permissionAdmin = 'admin';

export const navItems: NavItem[] = [
  {
    type: flight,
    name: 'Flight',
    icon: 'fa-plane',
    tab: flight,
    route: flight,
    permissions: [],
  },
  {
    type: hotel,
    name: 'Hotel',
    icon: 'fa-bed',
    tab: hotel,
    route: hotel,
    permissions: [],
  },
  {
    type: car_rental,
    name: 'Car Rental',
    icon: 'fa-car',
    tab: car_rental,
    route: car_rental,
    permissions: [],
  },
  {
    type: bookings,
    name: 'Bookings',
    icon: 'fa-ticket',
    tab: bookings,
    route: bookings,
    permissions: [],
  },
  {
    type: approvals,
    name: 'Approvals',
    icon: 'fa-list',
    tab: approvals,
    route: approvals,
    permissions: [],
  }
];
