<section class="">
  <div class="row">
    <div class="col-md-4 col-sm-6 ml-0 mr-0 col-5-containter pt-0">
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-sm-12">
          <div class="row mb-4 mt-4">
            <div class="col-sm-12 d-flex justify-content-center">
              <img class="icon-height" src="./assets/images/logo-transparent.svg" alt="logo">
            </div>
          </div>

          <div class="row mb-4 mt-4">
            <div class="col-sm-12 d-flex justify-content-center">
              <span class="link btn-link-active">
                SIGN IN
              </span>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-sm-8">
              <div *ngIf="showAlert">
                <alert-message [errorType]="alertType" [message]="alertMessage"></alert-message>
              </div>

              <form [formGroup]="loginForm">
                <div class="form-group">
                  <label for="email" class="label">Email</label>
                  <input type="text" class="form-control form-control-lg form-input-h input-field bg-light" formControlName="email"
                    id="email" autocomplete="email">
                  <span class="error"
                    *ngIf="loginForm.get('email').errors?.required && loginForm.get('email').touched">Email is
                    required</span>
                </div>

                <div class="form-group">
                  <label for="password" class="label">Password</label>
                  <input type="password" class="form-control form-control-lg form-input-h input-field bg-light" formControlName="password"
                    id="password" autocomplete="current-password">
                  <span class="error"
                    *ngIf="loginForm.get('password').errors?.required && loginForm.get('password').touched">Password is
                    required</span>
                </div>

                <div class="form-group">
                  <button class="btn btn-primary btn-block btn-lg mt-4" type="submit"
                    [disabled]="!loginForm.valid || loading" (click)="login()">
                    <span *ngIf="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                      Logging in
                    </span>
                    <span *ngIf="!loading">Login</span>
                  </button>
                </div>
              
                <div class="form-group">
                  <button class="btn btn-primary btn-block btn-lg" (click)="register()">
                    <span>Register</span>
                  </button>
                </div>

                <div class="form-inline form-group d-flex justify-content-end">
                  <label class="control-label">
                    <a href="javascript: void(0);" class="form-links" (click)="forgotPassword()">
                      <span class="forgot-password">Forgot Password?</span>
                    </a>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8 col-sm-6 ml-0 mr-0 left-pane mt-5">
      <div class="row mt-2 align-items-center">
        <div class="col-sm-12 d-flex justify-content-center">
          <!-- <img class="fade-in image-height" src="./assets/images/bulk_male.svg" alt="logo"> -->
        </div>
      </div>
    </div>
  </div>
</section>