import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: "app-approvals",
  templateUrl: "./approvals.component.html",
  styleUrls: ["./approvals.component.scss"]
})
export class ApprovalsComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();
  public roomsList = [{
    type: 'Room 1',
    ammenities: [{
      value: 'Wi-Wi'
    },{
      value: 'Breakfast Included'
    },{
      value: 'Work Station/Breakfast Nook'
    },{
      value: 'View of the Pool'
    }]
  },{
    type: 'Room 2',
    ammenities: [{
      value: 'Wi-Wi'
    },{
      value: 'Breakfast Included'
    },{
      value: 'Work Station/Breakfast Nook'
    },{
      value: 'View of the Pool'
    }]
  }]

  public carList = [{
    ammenities: [{
      value: 'Automatic'
    },{
      value: '50 000km'
    },{
      value: 'Small Car'
    }]
  },{
    ammenities: [{
      value: 'Automatic'
    },{
      value: '50 000km'
    },{
      value: 'Small Car'
    }]
  }]
  constructor(

  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }
}
