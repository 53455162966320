<div class="row hero">
  <div class="col-sm-12">
    <div class="section-container">
      <div class="row">
        <div class="col-sm-12">
          <status-stepper></status-stepper>
        </div>
      </div>
      <div class="mx-3 row d-flex">
        <div class="col-sm-12">
          <div class="header mt-4">Flight Application</div>
          <div class="subtitle mb-4 pt-1">Where would you like to visit?</div>
          <div class="row d-flex">
            <div class="col-sm-3">
              <custom-dropdown
                [options]="returnList"
                [width]="'100%'"
                [placeholder]="'Return'"
                (returnSelectionChanged)="returnChanged($event)"
              >
              </custom-dropdown>
            </div>
            <div class="col-sm-3">
              <custom-dropdown
                [options]="passengerOption"
                [width]="'100%'"
                [placeholder]="passengerOption[0].value"
                [adjustable]="true"
                (returnSelectionChanged)="returnChanged($event)"
              >
              </custom-dropdown>
            </div>
            <div class="col-sm-3">
              <custom-dropdown
                [options]="flightOption"
                [width]="'100%'"
                [placeholder]="flightOption[0].value"
                (returnSelectionChanged)="returnChanged($event)"
              >
              </custom-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex mt-5 mx-3">
        <div class="col-sm-4">
          <div class="title mb-2">Departure</div>
          <custom-input
            [options]="airportOptions"
            [placeholder]="airportOptions[0].value"
            [width]="'100%'"
            [icon]="'fa-plane'"
          ></custom-input>
        </div>
        <div class="col-sm-4">
          <div class="title mb-2">Where To?</div>
          <custom-input
            [options]="airportOptions"
            [placeholder]="airportOptions[0].value"
            [width]="'100%'"
            [icon]="'fa-map-marker'"
          ></custom-input>
        </div>
        <div class="col-sm-3">
          <div class="title mb-2">Date</div>
          <!-- <date-picker-range></date-picker-range> -->
          <date-picker></date-picker>
        </div>
      </div>
      <div class="row d-flex mt-5 mx-3">
        <div class="col-sm-3">
          <div class="title mb-2">Departure Times</div>
          <custom-dropdown
            [options]="returnList"
            [width]="'100%'"
            [placeholder]="'Morning'"
            [icon]="'fa-clock'"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
        <div class="col-sm-3">
          <div class="title mb-2">Stops</div>
          <custom-dropdown
            [options]="returnList"
            [width]="'100%'"
            [placeholder]="'All'"
            [icon]="'fa-map-marker'"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 my-5 mx-4">
          <button
            type="button"
            class="btn btn-primary search-button"
            (click)="viewFlightList()"
          >
            Search flights
            <i class="fa fa-long-arrow-right icon" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
