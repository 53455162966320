<section class="">
  <div class="row">
    <div class="col-md-8 col-sm-6 ml-0 mr-0 left-pane mt-5">
      <div class="row mt-2 align-items-center">
        <div class="col-sm-12 d-flex justify-content-center">
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-6 ml-0 mr-0 col-5-containter pt-0">
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-sm-12">
          <div class="row mb-4 mt-4">
            <div class="col-sm-12 d-flex justify-content-center">
              <img class="icon-height" src="./assets/images/logo-transparent.svg" alt="logo">
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12 d-flex justify-content-center">
              <span class="link btn-link-active">
                REGISTER
              </span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-sm-8">
              <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                <div *ngIf="showAlert">
                  <alert-message [errorType]="alertType" [message]="alertMessage"></alert-message>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="name" class="label">First name</label>
                      <input type="text" class="form-control form-control-lg form-input-h input-field" formControlName="name"
                        id="name">
                      <span class="error"
                        *ngIf="registrationForm.get('name').errors?.required && registrationForm.get('name').touched">First
                        name is required</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="surname" class="label">Surname</label>
                      <input type="text" class="form-control form-control-lg form-input-h input-field" formControlName="surname"
                        id="surname">
                      <span class="error"
                        *ngIf="registrationForm.get('surname').errors?.required && registrationForm.get('surname').touched">Surname
                        is required</span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="email" class="label">Email </label>
                  <input type="email" class="form-control form-control-lg form-input-h input-field" formControlName="email"
                    id="email">
                  <span class="error"
                    *ngIf="registrationForm.get('email').errors?.required && registrationForm.get('email').touched">Email
                    is required</span>
                  <span class="error"
                    *ngIf="registrationForm.get('email').touched && !registrationForm.get('email').errors?.required && registrationForm.get('email').errors?.email">Email
                    is invalid</span>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="password" class="label">Password</label>
                      <input type="password" class="form-control form-control-lg form-input-h input-field"
                        formControlName="password" id="password">
                      <span class="error"
                        *ngIf="registrationForm.get('password').errors?.required && registrationForm.get('password').touched">Password
                        is required</span>
                      <span class="error" *ngIf="registrationForm.get('password').hasError('minlength')">
                        Password should be atleast 6 characters</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="confirmPassword" class="label">Confirm password</label>
                      <input type="password" class="form-control form-control-lg form-input-h input-field"
                        formControlName="confirmPassword" id="confirmPassword" (change)="validatePasswordMatch()">
                      <span class="error"
                        *ngIf="registrationForm.get('confirmPassword').errors?.required && registrationForm.get('confirmPassword').touched">
                        Confirm password is required</span>
                        <!-- <br /> -->
                      <span class="error" *ngIf="matchPasswordError">
                        Passwords do not match</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group d-flex justify-content-center mt-3">
                  <button class="btn btn-primary btn-block btn-lg mt-4" type="submit"
                    [disabled]="!registrationForm.valid || loading">
                    <span *ngIf="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                      Registering
                    </span>
                    <span *ngIf="!loading">Register</span>
                  </button>
                </div> -->
                <div class="form-group">
                  <button class="btn btn-primary btn-block btn-lg mt-4" type="submit"
                    [disabled]="!registrationForm.valid || loading">
                    <span *ngIf="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                      Logging in
                    </span>
                    <span *ngIf="!loading">Register</span>
                  </button>
                </div>
                
                <div class="form-inline form-group d-flex justify-content-center">
                  <label class="control-label">
                    <a href="javascript: void(0);" class="form-links" (click)="onCancel()">
                      <span class="existing-account">Already have an account? Login here!</span>
                    </a>
                  </label>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>