import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Options } from 'src/app/interfaces/options';

@Component({
  selector: 'custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  public inputForm: NgForm;
  public isDropDownOpen: boolean = false;
  public dropdown: string = '';
  @Input('options') options: Options[];
  @Input('width') width: string;
  @Input('placeholder') placeholder: string;
  @Input('icon') icon: string = '';

  constructor() { }

  ngOnInit(): void {
    console.log(this.options)
    this.options.forEach((opt) => {
      opt.isActive = false;
    });
  }

  toggleDropdown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  closeDropdown() {
    this.isDropDownOpen = false;
  }

  changeInput(event: any) {
    this.isDropDownOpen = true;
  }
  

}
