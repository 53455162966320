<div
  class="row mb-5 border px-2 py-2 pr-4 d-flex align-content-center justify-content-between"
>
  <div class="row form-inline pl-3 py-2">
    <img class="hotel-image" src="{{ hotel.image }}" />
    <div class="flex-column pl-2">
        <div class="hotel-name">{{hotel.name}}</div>
        <div class="hotel-rating">{{hotel.rating}}</div>
        <div class="row form-inline pl-3 py-3">
            <div class="hotel-price">R {{hotel.pricePerNight}}</div>
            <div class="hotel-per-night pl-2">per night</div>
        </div>
        <div class="hotel-date">{{hotel.checkInDate}} - {{hotel.checkOutDate}}</div>
    </div>
  </div>

  <div class="row form-inline">
    <div class="col mr-2">
        <div class="contact-title ">Contact</div>
        <div class="row form-inline pt-2">
            <i class="fa fa-phone card-icon" aria-hidden="true"></i>
            <div class="contact-text pl-2">{{hotel.number}}</div>
        </div>
        <div class="row form-inline pt-2">
            <i class="fa fa-envelope card-icon" aria-hidden="true"></i>
            <div class="contact-text pl-2">{{hotel.email}}</div>
        </div>
    </div>
    <button type="button" class="btn-primary buy-button mt-2 mx-3" (click)="confirmHotel(hotel)">
      <div class="col">
        <div class="button-top-text">From</div>
        <div class="button-price">R {{ hotel.price }}</div>
      </div>
    </button>
  </div>
</div>
