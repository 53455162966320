import { Component, Input, OnInit } from '@angular/core';
import { HotelRoomOptions } from 'src/app/interfaces/hotel-room-options';

@Component({
  selector: 'custom-hotel-room-card',
  templateUrl: './hotel-room-card.component.html',
  styleUrls: ['./hotel-room-card.component.scss']
})
export class HotelRoomCardComponent implements OnInit {
  @Input('room') room: HotelRoomOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
