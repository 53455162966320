<form #dropdownForm="ngForm">
  <div class="">
    <div
      class="input-group mb-2"
      [ngStyle]="{ width: width }"
      (click)="toggleDropdown()"
    >
      <div class="input-group-prepend" *ngIf="icon.length > 0">
        <div class="input-group-text white-bg">
          <i class="fa {{ icon }}" aria-hidden="true"></i>
        </div>
      </div>
      <div
        name="dropdown"
        class="form-control input"
        [ngClass]="icon.length > 0 && 'hide-border'"
        type="text"
      >
        <div class="dropdown-text">{{ dropdown }}</div>
      </div>
      <div class="input-group-append">
        <div class="input-group-text background-white round-border">
          <span class="caret down"></span>
        </div>
      </div>
    </div>
  </div>

  <ul
    class="list-group"
    [ngStyle]="{ width: width }"
    *ngIf="isDropDownOpen"
    (mouseleave)="closeDropdown()"
  >
    <div *ngIf="!adjustable">
      <li
        [ngClass]="item.isActive ? 'activeOption' : ''"
        (click)="selectOption($event, index)"
        *ngFor="let item of options; let index = index"
        class="list-group-item"
      >
        <div class="dropdown-option">{{ item.value }}</div>
      </li>
    </div>
    <div *ngIf="adjustable">
      <li
        [ngClass]="item.isActive ? 'activeOption' : ''"
        (click)="selectOption($event, index)"
        *ngFor="let item of options; let index = index"
        class="list-group-item"
      >
        <div class="d-flex justify-content-between">
          <div
            class="adjuster-container"
            (click)="adjustQuantity($event, index, 'decrease')"
          >
            <div class="adjuster">-</div>
          </div>
          <div class="column-layout">
            <div class="dropdown-option">
              {{ item.quantity }} {{ item.value }}
            </div>
            <div class="dropdown-option">{{ item.subtitle }}</div>
          </div>
          <div
            class="adjuster-container"
            (click)="adjustQuantity($event, index, 'increase')"
          >
            <div class="adjuster">+</div>
          </div>
        </div>
      </li>
    </div>
  </ul>
</form>
