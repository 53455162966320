import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "src/app/shared/endpoints.enum";
import { AuthGuardService } from "src/app/services/auth-guard.service";

import { ApprovalsComponent } from "./approvals/approvals.component";
import { BookingsComponent } from "./bookings/bookings.component";
import { CarRentalComponent } from "./car-rental/car-rental.component";
import { FlightComponent } from "./flight/flight.component";
import { HomeComponent } from "./home/home.component";
import { HotelComponent } from "./hotel/hotel.component";
import { TravelerComponent } from "./traveler/traveler.component";
import { LandingComponent } from "./landing/landing.component";
import { FlightListsComponent } from "./flight/flight-lists/flight-lists.component";
import { HotelListsComponent } from "./hotel/hotel-lists/hotel-lists.component";
import { CarRentalListsComponent } from "./car-rental/car-rental-lists/car-rental-lists.component";
import { HotelRoomListComponent } from "./hotel/hotel-room-list/hotel-room-list.component";
import { UsersComponent } from "./users/users.component";
import { UserComponent } from "./users/user/user.component";
import { UserListComponent } from "./users/user-list/user-list.component";

const routes: Routes = [
  {
    path: "",
    component: LandingComponent,
    data: { title: "Travel-X" },
    children: [
      { path: AppRoutes.APPROVALS, component: ApprovalsComponent },
      { path: AppRoutes.BOOKINGS, component: BookingsComponent },
      { path: AppRoutes.CAR_RENTAL, component: CarRentalComponent },
      { path: AppRoutes.CAR_RENTAL_LIST, component: CarRentalListsComponent },
      { path: AppRoutes.FLIGHT, component: FlightComponent },
      { path: AppRoutes.FLIGHT_LIST, component: FlightListsComponent },
      { path: AppRoutes.HOME, component: HomeComponent },
      { path: AppRoutes.HOTEL, component: HotelComponent },
      { path: AppRoutes.HOTEL_LIST, component: HotelListsComponent },
      { path: AppRoutes.HOTEL_ROOM_LIST, component: HotelRoomListComponent },
      { path: AppRoutes.TRAVELER, component: TravelerComponent },
      { path: AppRoutes.USERS, component: UsersComponent },
      { path: AppRoutes.USER, component: UserComponent },
      { path: AppRoutes.USER_LIST, component: UserListComponent },
      { path: "", redirectTo: AppRoutes.LANDING, pathMatch: "full" },
    ],
    // canActivate: [AuthGuardService],
    canActivate: [],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
