<div class="containter">
  <div class="row">
    <div class="" [ngClass]="carList">
      <div class="d-flex wrapper" style="background-color: white">
        <div
          class="bg-light border-right m-0 p-0"
          id="sidebar-wrapper"
          [ngClass]="status ? 'hide' : 'show'"
        >
          <div class="sidebar-container row m-0 p-0">
            <div class="p-0 m-0 left-section">
              <div class="row justify-content-between ml-2 pt-4">
                <div class="filter-header">Milage</div>
                <i
                  class="fa fa-times close-icon"
                  aria-hidden="true"
                  (click)="clickEvent()"
                ></i>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Price Per Day</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'All'"
                  (returnSelectionChanged)="({})"
                >
                </custom-dropdown>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Transmission</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'All'"
                  (returnSelectionChanged)="({})"
                >
                </custom-dropdown>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Car Category</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'All'"
                  (returnSelectionChanged)="({})"
                >
                </custom-dropdown>
              </div>
            </div>
            <div
              class="row p-0 m-0 right-section"
              (click)="clickEvent()"
              *ngIf="status"
            >
              <div class="col m-0 p-0">
                <i class="fa fa-arrow-right icon" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        <div id="page-content-wrapper">
          <div class="row form-inline justify-content-between ml-3">
            <div class="row form-inline ml-3 mt-3 clickable" (click)="goBack()">
              <div class="bread-crumb">Car Rentals</div>
              <div class="bread-crumb ml-3 mr-3">></div>
              <div class="bread-crumb">Search Rentals</div>
            </div>
            <button
              type="button"
              class="btn-primary back-button mt-2"
              (click)="goBack()"
            >
              <div class="row justify-content-center form-inline">
                <i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i>
                <div class="button-text">BACK</div>
              </div>
            </button>
          </div>
          <div class="row form-inline justify-content-between ml-3 mt-5">
            <div class="title ml-3">Select Vehicle</div>
            <div class="row mr-3 form-inline">
              <div class="header mr-3">Sort By</div>
              <custom-dropdown
                [options]="[]"
                [width]="'115%'"
                [placeholder]="'Cheapest'"
                (returnSelectionChanged)="({})"
              ></custom-dropdown>
            </div>
          </div>
          <div class="ml-3 mt-5">
            <div class="col ml-3" *ngFor="let car of cars">
              <custom-car-card
                [car]="car"
                (carSelection)="carSelected(car)"
              ></custom-car-card>
            </div>
          </div>
          <div class="row d-flex justify-content-center my-5">
            <button type="button" class="btn btn-primary load-more-button">
              <div class="row form-inline justify-content-center">
                <i class="fa fa-long-arrow-down pr-3" aria-hidden="true"></i>
                <div class="button-text py-2">LOAD MORE</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="justify-content-center align-items-center py-5 confirmBooking"
      *ngIf="carConfirm.length > 0"
      [ngClass]="carConfirm"
    >
      <div class="row justify-content-center confirmBooking-title">
        Car Rental
      </div>
      <div class="row justify-content-center filter-sub-header mt-3">
        R 1520
      </div>

      <div class="row justify-content-center mt-3">
        <button type="button" class="row btn-primary book-button mt-3">
          <div class="book-button-text">BOOK</div>
        </button>
      </div>

      <div class="row form-inline justify-content-center">
        <img class="side-tab-image mt-5" src="{{ selectedCar.agencyImage }}" />
      </div>

      <div class="row bold-car-label justify-content-center mt-5">
        {{selectedCar.name}}
      </div>

      <div class="row vehicle-specs justify-content-center mt-2">
        {{selectedCar.specs}}
      </div>


      <div class="row form-inline justify-content-center mt-3">
        <div class="date grey-bold-text">{{selectedCar.checkInDate}}</div>
        <div class="grey-bold-text">10:00</div>
      </div>
      <div class="row form-inline justify-content-center">
        <div class="date grey-bold-text">{{selectedCar.checkOutDate}}</div>
        <div class="grey-bold-text">12:00</div>
      </div>
    </div>
  </div>
</div>
