<div class="col">
  <div class="row">
    <div class="col-sm-8 pt-5 bg-white">
      <div class="title">Bookings</div>
      <div class="row">
        <div class="col-sm-6 pl-3 pr-0 border-right padding-horizontal-15">
          <div class="subtitle pt-3">Flights</div>

          <div class="row pt-4 pl-4 pr-5 form-inline justify-content-between">
            <div class="left-section-bold-text">JHB-CPT</div>
            <div class="left-section-normal-text">Economy class</div>
            <div class="row form-inline">
              <div class="number-users">1</div>
              <i class="fa fa-user sub-icons" aria-hidden="true"></i>
            </div>
          </div>
          <div class="row pl-4 form-inline pt-3">
            <div class="left-section-grey-text">Depart</div>
            <div class="left-section-fine-grey-text pl-2 pt-1">4h</div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-12">
              <div class="row mt-3 mb-3">
                <div class="col-sm-2 calendar d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-calendar-event"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"
                    />
                  </svg>
                </div>
                <div class="col-sm-10">
                  <span>Fri, 22 Dec 2023</span>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-sm-2 rotate-180 d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-airplane-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849"
                    />
                  </svg>
                </div>
                <div class="col-sm-10">
                  <fieldset class="scheduler-border">
                    <legend class="scheduler-border">2h</legend>
                    <ng-container class="">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="row">
                            <div class="col-sm-12">8:30 jhb</div>
                            <div class="col-sm-12">10:30 cpt</div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <img
                            class="icon-height"
                            src="../../../../assets/images/flights/emirates.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </fieldset>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-2"></div>
                <div class="col-sm-1 icon-color">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-clock-history"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"
                    />
                    <path
                      d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"
                    />
                    <path
                      d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                </div>
                <div class="col-sm-8">
                  <span class="icon-color">30min</span>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-sm-2 rotate-180 d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-airplane-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849"
                    />
                  </svg>
                </div>
                <div class="col-sm-10">
                  <fieldset class="scheduler-border">
                    <legend class="scheduler-border">2h</legend>
                    <ng-container class="">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="row">
                            <div class="col-sm-12">8:30 jhb</div>
                            <div class="col-sm-12">10:30 cpt</div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <img
                            class="icon-height"
                            src="../../../../assets/images/flights/emirates.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="col">
            <div
              class="row d-flex justify-content-center form-inline border-bottom"
            >
              <div class="left-section-grey-text mb-2">Status:</div>
              <div class="left-section-fine-big-grey-text pl-5 mb-2">
                Pending
              </div>
              <i
                class="fa fa-clock-o orange-icon pl-2 mb-2"
                aria-hidden="true"
              ></i>
            </div>
            <div
              class="d-flex justify-content-end margin-right-25 pt-1 individual-price"
            >
              R 3353
            </div>
          </div>
          <div class="row pl-4 form-inline pt-5">
            <div class="left-section-grey-text">Return</div>
            <div class="left-section-fine-grey-text pl-2 pt-1">4h</div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <div class="row mt-3 mb-3">
                <div class="col-sm-2 calendar d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-calendar-event"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"
                    />
                  </svg>
                </div>
                <div class="col-sm-10">
                  <span>Fri, 22 Dec 2023</span>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-sm-2 rotate-180 d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-airplane-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849"
                    />
                  </svg>
                </div>
                <div class="col-sm-10">
                  <fieldset class="scheduler-border">
                    <legend class="scheduler-border">2h</legend>
                    <ng-container class="">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="row">
                            <div class="col-sm-12">8:30 jhb</div>
                            <div class="col-sm-12">10:30 cpt</div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <img
                            class="icon-height"
                            src="../../../../assets/images/flights/emirates.png"
                            alt="logo"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end pt-3">
        <button type="button" class="btn btn-primary button">
          <div class="row justify-content-center form-inline">
            <i class="fa fa-pencil pr-2" aria-hidden="true"></i>
            <div class="button-text">EDIT FLIGHT</div>
          </div>
        </button>
      </div>

      <div class="row space-divider">
        <div class="col-sm-6 pl-3 pr-0 border-right padding-horizontal-15">
          <div class="subtitle pt-3">Hotel</div>

          <div class="row pt-4 pl-4 pr-5 form-inline justify-content-between">
            <div class="left-section-bold-text">Presedential Hotel</div>
            <div class="row form-inline">
              <div class="number-users">1</div>
              <i class="fa fa-user sub-icons" aria-hidden="true"></i>
            </div>
          </div>
          <div class="row pl-4 form-inline pt-3">
            <div class="left-section-grey-text">Check In</div>
          </div>
          <div class="row pl-4 form-inline pt-2">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
            <div class="left-section-normal-text pl-4">2024-12-11</div>
          </div>

          <div class="col pt-5" *ngFor="let room of roomsList">
            <div class="row form-inline mb-2">
              <div class="left-section-bold-text">Room Type:</div>
              <div class="left-section-big-normal-text pl-2">
                {{ room.type }}
              </div>
            </div>
            <div *ngFor="let ammenity of room.ammenities">
              <div class="flight-time-normal pt-2">{{ ammenity.value }}</div>
            </div>
          </div>

          <div class="row form-inline pt-5 pl-4">
            <div class="left-section-bold-text">Reservation Number:</div>
            <div class="left-section-big-normal-text pl-2">165123123123</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="col">
            <div
              class="row d-flex justify-content-center form-inline border-bottom"
            >
              <div class="left-section-grey-text mb-2">Status:</div>
              <div class="left-section-fine-big-grey-text pl-5 mb-2">
                Pending
              </div>
              <i
                class="fa fa-clock-o orange-icon pl-2 mb-2"
                aria-hidden="true"
              ></i>
            </div>
            <div
              class="d-flex justify-content-end margin-right-25 pt-1 individual-price"
            >
              R 3353
            </div>
          </div>
          <div class="row pl-4 form-inline pt-5">
            <div class="left-section-grey-text">Check Out</div>
          </div>
          <div class="row pl-4 form-inline pt-2">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
            <div class="left-section-normal-text pl-4">2024-12-11</div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end pt-3 align-self-end">
        <button type="button" class="btn btn-primary button">
          <div class="row justify-content-center form-inline">
            <i class="fa fa-pencil pr-2" aria-hidden="true"></i>
            <div class="button-text">EDIT HOTEL</div>
          </div>
        </button>
      </div>

      <div class="row space-divider">
        <div class="col-sm-6 pl-3 pr-0 border-right padding-horizontal-15">
          <div class="subtitle pt-3">Car Rental</div>

          <div class="row pt-4 pl-4 pr-5 form-inline justify-content-between">
            <div class="left-section-bold-text">Avis</div>
            <div class="row form-inline">
              <div class="number-users">1</div>
              <i class="fa fa-user sub-icons" aria-hidden="true"></i>
            </div>
          </div>
          <div class="row pl-4 form-inline pt-3">
            <div class="left-section-grey-text">Pick Up</div>
          </div>
          <div class="row pl-4 form-inline pt-2">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
            <div class="left-section-normal-text pl-4">2024-12-11</div>
          </div>

          <div class="col pt-5" *ngFor="let car of carList">
            <div class="row form-inline mb-2">
              <div class="left-section-bold-text">Vehicle Specification:</div>
            </div>
            <div *ngFor="let ammenity of car.ammenities">
              <div class="flight-time-normal pt-2">{{ ammenity.value }}</div>
            </div>
          </div>

          <div class="row form-inline pt-5 pl-4">
            <div class="left-section-bold-text">Reservation Number:</div>
            <div class="left-section-big-normal-text pl-2">165123123123</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="col">
            <div
              class="row d-flex justify-content-center form-inline border-bottom"
            >
              <div class="left-section-grey-text mb-2">Status:</div>
              <div class="left-section-fine-big-grey-text pl-5 mb-2">
                Pending
              </div>
              <i
                class="fa fa-clock-o orange-icon pl-2 mb-2"
                aria-hidden="true"
              ></i>
            </div>
            <div
              class="d-flex justify-content-end margin-right-25 pt-1 individual-price"
            >
              R 3353
            </div>
          </div>
          <div class="row pl-4 form-inline pt-5">
            <div class="left-section-grey-text">Drop Off</div>
          </div>
          <div class="row pl-4 form-inline pt-2">
            <i class="fa fa-calendar-o" aria-hidden="true"></i>
            <div class="left-section-normal-text pl-4">2024-12-11</div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end pt-3 align-self-end mb-5">
        <button type="button" class="btn btn-primary button">
          <div class="row justify-content-center form-inline">
            <i class="fa fa-pencil pr-2" aria-hidden="true"></i>
            <div class="button-text">EDIT RENTAL</div>
          </div>
        </button>
      </div>
    </div>

    <div class="col-sm-4 right-section pt-2 pr-3 sticky-content">
      <div class="row">
        <div class="col-sm-6">
          <button type="button" class="btn btn-primary button">
            <div class="row justify-content-center form-inline">
              <div class="button-text">ACCEPT ALL</div>
            </div>
          </button>
        </div>
        <div class="col-sm-6">
          <button type="button" class="btn btn-warning button">
            <div class="row justify-content-center form-inline">
              <div class="button-text">REJECT ALL</div>
            </div>
          </button>
        </div>
      </div>
      <div class="row pl-4 pr-5 mt-5 d-flex justify-content-between">
        <div class="col-sm-6">
          <div class="subtitle">Reason for Travel</div>
          <div class="normal-text pt-2">Board meetings</div>
        </div>
        <div class="col-sm-6">
          <div class="form-inline">
            <i class="fa fa-usd icon" aria-hidden="true"></i>
            <div class="subtitle">Estimated Total</div>
          </div>
          <div class="cost pt-2">R 7895</div>
        </div>
        <div class="col-sm-12">
          <div class="row pt-5 pl-3 form-inline">
            <div class="header width-150">Travellers</div>
            <div class="number-users">1</div>
            <i class="fa fa-user sub-icons" aria-hidden="true"></i>
          </div>
          <div class="row pt-3 pl-3 form-inline">
            <div class="normal-text width-150">Mr M. Bisht</div>
            <i class="fa fa-pencil sub-icons" aria-hidden="true"></i>
          </div>

          <div class="row pt-5 pl-3 form-inline">
            <div class="text-bold width-150">Cost Centre:</div>
            <div class="normal-text">-</div>
          </div>

          <div class="row pt-5 pl-3 form-inline">
            <div class="text-bold width-150">Department:</div>
            <div class="normal-text">department</div>
          </div>

          <div class="row pt-5 pl-3 form-inline">
            <div class="text-bold width-150">Approver:</div>
            <div class="normal-text">approver</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
