import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarOptions } from 'src/app/interfaces/car-options';
import { AppRoutes } from 'src/app/shared/endpoints.enum';

@Component({
  selector: 'app-car-rental-lists',
  templateUrl: './car-rental-lists.component.html',
  styleUrls: ['./car-rental-lists.component.scss']
})
export class CarRentalListsComponent implements OnInit {
  status: boolean = true;
  carList = 'col-sm-12';
  carConfirm = '';
  selectedCar: CarOptions = null;

  public cars: CarOptions[] = [
    {
      image: '../../../../../assets/images/cars/car-1.png',
      agencyImage: '../../../../../assets/images/car-agencies/agency-1.png',
      name: 'Kia Picanto',
      specs: 'Vehicle specifications',
      checkInDate: 'Fri, 22 Dec 2023',
      checkOutDate: 'Thurs, 28 Dec 2023',
      pricePerDay: 177,
      price: 1240
    },
    {
      image: '../../../../../assets/images/cars/car-2.png',
      agencyImage: '../../../../../assets/images/car-agencies/agency-1.png',
      name: 'Kia Picanto',
      specs: 'Vehicle specifications',
      checkInDate: 'Fri, 22 Dec 2023',
      checkOutDate: 'Thurs, 28 Dec 2023',
      pricePerDay: 177,
      price: 1240
    },
    {
      image: '../../../../../assets/images/cars/car-3.png',
      agencyImage: '../../../../../assets/images/car-agencies/agency-2.png',
      name: 'Kia Picanto',
      specs: 'Vehicle specifications',
      checkInDate: 'Fri, 22 Dec 2023',
      checkOutDate: 'Thurs, 28 Dec 2023',
      pricePerDay: 177,
      price: 1240
    },
    {
      image: '../../../../../assets/images/cars/car-4.png',
      agencyImage: '../../../../../assets/images/car-agencies/agency-3.png',
      name: 'Kia Picanto',
      specs: 'Vehicle specifications',
      checkInDate: 'Fri, 22 Dec 2023',
      checkOutDate: 'Thurs, 28 Dec 2023',
      pricePerDay: 177,
      price: 1240
    },
    {
      image: '../../../../../assets/images/cars/car-5.png',
      agencyImage: '../../../../../assets/images/car-agencies/agency-3.png',
      name: 'Kia Picanto',
      specs: 'Vehicle specifications',
      checkInDate: 'Fri, 22 Dec 2023',
      checkOutDate: 'Thurs, 28 Dec 2023',
      pricePerDay: 177,
      price: 1240
    },
  ];
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  clickEvent() {
    this.status = !this.status;
  }

  goBack() {
    this.router.navigate([AppRoutes.CAR_RENTAL]);
  }

  carSelected(car) {
    this.carList = 'col-sm-9';
    this.carConfirm = 'col-sm-3';
    this.selectedCar = car;
  }

}
