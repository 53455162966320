import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Options } from 'src/app/interfaces/options';

@Component({
  selector: 'status-stepper',
  templateUrl: './status-stepper.component.html',
  styleUrls: ['./status-stepper.component.scss']
})
export class StatusStepperComponent implements OnInit {

  activeStep: number = 1;
  stepper = [
    {
      id: 1,
      name: "Who's Traveling",
      status: "complete",
      icon: ""
    },
    {
      id: 2,
      name: "Reason for Travel",
      status: "complete",
      icon: ""
    },
    {
      id: 3,
      name: "Book Flight",
      status: "inprogress",
      icon: ""
    },
    {
      id: 4,
      name: "Book Lodging",
      status: "todo",
      icon: ""
    },
    {
      id: 5,
      name: "Book Vehicle",
      status: "todo",
      icon: ""
    },
    {
      id: 6,
      name: "Confirm",
      status: "todo",
      icon: ""
    },
  ];

  constructor() { }

  ngOnInit(): void {
    this.stepper.forEach((step, index) => {
      this.buildStepStyle(step.status, index);
    })
  }

  buildStepStyle(status, index) {
    if (status == 'complete') {
      this.stepper[index].icon = 'bi bi-check-circle-fill status'
    }
    if (status == 'inprogress') {
      this.stepper[index].icon = 'bi bi-circle-fill status'
    }
    if (status == 'todo') {
      this.stepper[index].icon = 'bi bi-circle status'
    }
  }

}
