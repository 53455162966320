<div
  class="row mb-5 border px-2 py-2 pr-4 d-flex align-content-center justify-content-between"
>
  <div class="row form-inline pl-3">
    <img class="car-image" src="{{ rentalCar.image }}" />
    <div class="flex-column pl-2">
      <div class="car-name">{{ rentalCar.name }}</div>
      <div class="vehicle-specs py-3">{{ rentalCar.specs }}</div>
      <div class="request-date">
        {{ rentalCar.checkInDate }} - {{ rentalCar.checkOutDate }}
      </div>
    </div>
  </div>

  <div class="row form-inline">
    <div class="col mr-2">
      <img class="agency-image" src="{{ rentalCar.agencyImage }}" />
      <div class="row form-inline pt-2">
        <div class="price-per-day">R {{ rentalCar.pricePerDay }}</div>
        <div class="price-per-day-text pl-2">per day</div>
      </div>
    </div>
    <button type="button" class="btn-primary buy-button mt-2" (click)="confirmCar(rentalCar)">
      <div class="col">
        <div class="button-top-text">From</div>
        <div class="button-price">R {{ rentalCar.price }}</div>
      </div>
    </button>
  </div>
</div>
