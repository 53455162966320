import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HotelOptions } from 'src/app/interfaces/hotel-options';

@Component({
  selector: 'custom-hotel-card',
  templateUrl: './hotel-card.component.html',
  styleUrls: ['./hotel-card.component.scss']
})
export class HotelCardComponent implements OnInit {
  @Input('hotel') hotel: HotelOptions;
  @Output() hotelSelected = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  confirmHotel(hotel: any) {
    this.hotelSelected.emit(hotel);
  }

}
