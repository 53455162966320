<div class="d-flex wrapper" style="background-color: white;">
    <div
      class="bg-light border-right m-0 p-0"
      id="sidebar-wrapper"
      [ngClass]="status ? 'hide' : 'show'"
    >
      <div class="sidebar-container row m-0 p-0">
        <div class="p-0 m-0 left-section">
          <div class="row justify-content-between ml-2 pt-4">
            <div class="filter-header">Filters</div>
            <i
              class="fa fa-times close-icon"
              aria-hidden="true"
              (click)="clickEvent()"
            ></i>
          </div>
          <div class="flex-column mt-4 ml-3">
            <div class="filter-sub-header mb-2">Availability</div>
            <custom-dropdown
              [options]="[]"
              [width]="status ? '100%' : '110%'"
              [placeholder]="'All'"
              (returnSelectionChanged)="({})"
            >
            </custom-dropdown>
          </div>
          <div class="flex-column mt-4 ml-3">
            <div class="filter-sub-header mb-2">Budget</div>
            <custom-dropdown
              [options]="[]"
              [width]="status ? '100%' : '110%'"
              [placeholder]="'All'"
              (returnSelectionChanged)="({})"
            >
            </custom-dropdown>
          </div>
          <div class="flex-column mt-4 ml-3">
            <div class="filter-sub-header mb-2">Star Ratings</div>
            <custom-dropdown
              [options]="[]"
              [width]="status ? '100%' : '110%'"
              [placeholder]="'All'"
              (returnSelectionChanged)="({})"
            >
            </custom-dropdown>
          </div>
          <div class="flex-column mt-4 ml-3">
            <div class="filter-sub-header mb-2">Distance</div>
            <custom-dropdown
              [options]="[]"
              [width]="status ? '100%' : '110%'"
              [placeholder]="'All'"
              (returnSelectionChanged)="({})"
            >
            </custom-dropdown>
          </div>
          <div class="flex-column mt-4 ml-3">
            <div class="filter-sub-header mb-2">Popular Filters</div>
            <custom-dropdown
              [options]="[]"
              [width]="status ? '100%' : '110%'"
              [placeholder]="'All'"
              (returnSelectionChanged)="({})"
            >
            </custom-dropdown>
          </div>
        </div>
        <div
          class="row p-0 m-0 right-section"
          (click)="clickEvent()"
          *ngIf="status"
        >
          <div class="col m-0 p-0">
            <i class="fa fa-arrow-right icon" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  
    <div id="page-content-wrapper">
      <div class="row form-inline justify-content-between ml-3">
        <div class="row form-inline ml-3 mt-3 clickable" (click)="goBack()">
          <div class="bread-crumb">Hotels</div>
          <div class="bread-crumb ml-3 mr-3">></div>
          <div class="bread-crumb">Search Hotels</div>
          <div class="bread-crumb ml-3 mr-3">></div>
          <div class="bread-crumb">Select Hotel</div>
        </div>
        <button
          type="button"
          class="btn-primary back-button mt-2"
          (click)="goBack()"
        >
        <div class="row justify-content-center form-inline">
          <i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i>
          <div class="button-text">BACK</div>
        </div>
        </button>
      </div>
      <div class="row form-inline justify-content-between ml-3 mt-5">
          <div class="title ml-3">Select Hotel</div>
          <div class="row mr-3 form-inline">
              <div class="header mr-3">Sort By</div>
              <custom-dropdown
              [options]="[]"
              [width]="'115%'"
              [placeholder]="'Cheapest'"
              (returnSelectionChanged)="({})"
            ></custom-dropdown>
          </div>
      </div>
      <div class="ml-3 mt-5">
          <div class="col ml-3" *ngFor="let room of rooms">
          <custom-hotel-room-card [room]="room"></custom-hotel-room-card>
          </div>
      </div>
      <div class="row d-flex justify-content-center my-5">
        <button type="button" class="btn btn-primary load-more-button">
          <div class="row form-inline justify-content-center">
            <i class="fa fa-long-arrow-down pr-3" aria-hidden="true"></i>
            <div class="button-text py-2">LOAD MORE</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  