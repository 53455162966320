<div
  class="row mb-5 border px-2 py-2 pr-4 d-flex align-content-center justify-content-between"
>
<div>
    <img class="flight-image" src="{{ flight.image }}" />
</div>
  <div class="row form-inline">
    <div class="flex-column">
      <div class="bold-text">{{ flight.departTime }}</div>
      <div class="normal-text pt-1">{{ flight.departDate }}</div>
    </div>
    <div class="row ml-3 mr-3">
      <div class="col">
        <div class="form-inline">
          <i class="fa fa-plane" aria-hidden="true"></i>
          <div class="col">
            <div class="blue-line mr-3 ml-2"></div>
            <div class="row pt-1 fixed-container">
              <div class="flight-duration pr-2">
                {{ flight.flightDuration }}
              </div>
              <div class="flight-duration">
                {{ flight.numberOfStops }} stops
              </div>
            </div>
          </div>
          <i class="fa fa-map-marker" aria-hidden="true"></i>
        </div>

        <div
          class="form-inline pt-3 pl-0"
          style="position: absolute; left: 2; right: 0"
        >
          <div class="grey-text-bold">JHB</div>
          <div class="col">
            <div class="white-line mr-3 ml-2"></div>
          </div>
          <div class="grey-text-bold">CPT</div>
        </div>
      </div>
    </div>
    <div class="flex-column mr-5">
      <div class="bold-text">{{ flight.arrivalTime }}</div>
      <div class="normal-text pt-1">{{ flight.arrivalDate }}</div>
    </div>
    <div class="bold-text">{{ flight.flightNo }}</div>
  </div>

  <button type="button" class="btn-primary buy-button mt-2" (click)="flightClick()">
    <div class="col">
        <div class="button-top-text">From</div>
        <div class="button-price">R 2532</div>
    </div>
  </button>
</div>
