<div class="containter">
  <div class="row">
    <div class="" [ngClass]="bookingList">
      <div class="d-flex wrapper" style="background-color: white">
        <div
          class="bg-light border-right m-0 p-0"
          id="sidebar-wrapper"
          [ngClass]="status ? 'hide' : 'show'"
        >
          <div class="sidebar-container row m-0 p-0">
            <div class="p-0 m-0 left-section">
              <div class="row justify-content-between ml-2 pt-4">
                <div class="filter-header">Filters</div>
                <i
                  class="fa fa-times close-icon"
                  aria-hidden="true"
                  (click)="clickEvent()"
                ></i>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Departure</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'Morning'"
                  (returnSelectionChanged)="({})"
                  [icon]="'fa-clock-o'"
                >
                </custom-dropdown>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Stops</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'All'"
                  (returnSelectionChanged)="({})"
                  [icon]="'fa-map-marker'"
                >
                </custom-dropdown>
              </div>
              <div class="flex-column mt-4 ml-3">
                <div class="filter-sub-header mb-2">Airline</div>
                <custom-dropdown
                  [options]="[]"
                  [width]="status ? '100%' : '110%'"
                  [placeholder]="'All'"
                  (returnSelectionChanged)="({})"
                  [icon]="'fa-plane'"
                >
                </custom-dropdown>
              </div>
            </div>
            <div
              class="row p-0 m-0 right-section"
              (click)="clickEvent()"
              *ngIf="status"
            >
              <div class="col m-0 p-0">
                <i class="fa fa-arrow-right icon" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        <div id="page-content-wrapper">
          <div class="row form-inline justify-content-between ml-3 mr-3">
            <div class="row form-inline ml-3 mt-3 clickable" (click)="goBack()">
              <div class="bread-crumb">Flights</div>
              <div class="bread-crumb ml-3 mr-3">></div>
              <div class="bread-crumb">Search Flights</div>
            </div>
            <button
              type="button"
              class="btn-primary back-button mt-2"
              (click)="goBack()"
            >
              <div class="row justify-content-center form-inline">
                <i class="fa fa-long-arrow-left pr-2" aria-hidden="true"></i>
                <div class="button-text">BACK</div>
              </div>
            </button>
          </div>
          <div class="row form-inline justify-content-between ml-3 mr-3 mt-5">
            <div class="title ml-3">Select Departing flight</div>
            <div class="row mr-3 form-inline">
              <div class="header mr-3">Sort By</div>
              <custom-dropdown
                [options]="[]"
                [width]="'115%'"
                [placeholder]="'Cheapest'"
                (returnSelectionChanged)="({})"
              ></custom-dropdown>
            </div>
          </div>
          <div class="row d-flex ml-3 mr-3 mt-3">
            <div class="col-sm-2">
              <custom-dropdown
                [options]="[]"
                [width]="'100%'"
                [placeholder]="'Return'"
                (returnSelectionChanged)="({})"
              >
              </custom-dropdown>
            </div>
            <div class="col-sm-2">
              <custom-dropdown
                [options]="[]"
                [width]="'100%'"
                [placeholder]="'1 Adult, 0 Kids'"
                [adjustable]="true"
                (returnSelectionChanged)="({})"
              >
              </custom-dropdown>
            </div>
            <div class="col-sm-2">
              <custom-dropdown
                [options]="[]"
                [width]="'100%'"
                [placeholder]="'Economy'"
                (returnSelectionChanged)="({})"
              >
              </custom-dropdown>
            </div>
          </div>
          <div class="row form-inline justify-content-between ml-3 mr-3 mt-2">
            <div class="row ml-3">
              <div class="label">O.R. Tambo International Airport</div>
              <i
                class="fa fa-long-arrow-right ml-3 mr-3"
                aria-hidden="true"
              ></i>
              <div class="label">Cape Town, South Africa</div>
            </div>
            <div class="row form-inline">
              <div class="label mr-3">Date</div>
              <custom-dropdown
                [options]="[]"
                [width]="'100%'"
                [placeholder]="'Fri, 22 Dec - Thurs, 28 Dec'"
                (returnSelectionChanged)="({})"
              ></custom-dropdown>
            </div>
          </div>
          <div class="ml-3 mr-3 mt-5">
            <div class="col ml-3" *ngFor="let plane of flights">
              <custom-flight-card
                [flight]="plane"
                (flightSelection)="flightReceived(plane)"
              ></custom-flight-card>
            </div>
          </div>
          <div class="row d-flex justify-content-center my-5">
            <button type="button" class="btn btn-primary load-more-button">
              <div class="row form-inline justify-content-center">
                <i class="fa fa-long-arrow-down pr-3" aria-hidden="true"></i>
                <div class="button-text py-2">LOAD MORE</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="justify-content-center align-items-center py-5 confirmBooking"
      [ngClass]="bookingConfirm"
    >
      <div class="row justify-content-center confirmBooking-title">
        Flight Details
      </div>
      <div class="row justify-content-center filter-sub-header mt-3">
        R 1520
      </div>
      <div class="row form-inline justify-content-center mt-4">
        <i class="fa fa-user" aria-hidden="true"></i>
        <div class="label ml-2">1 Traveller</div>
      </div>

      <div class="row justify-content-center mt-3">
        <button type="button" class="row btn-primary book-button mt-3">
          <div class="book-button-text">BOOK</div>
        </button>
      </div>

      <div class="row form-inline justify-content-between px-5 mt-5">
        <div
          (click)="setActive(sideTabs[0].name)"
          [ngClass]="sideTabs[0].active ? 'active-tab' : 'inactive-tab '"
        >
          {{ sideTabs[0].name }}
        </div>
        <div
          (click)="setActive(sideTabs[1].name)"
          [ngClass]="sideTabs[1].active ? 'active-tab' : 'inactive-tab '"
        >
          {{ sideTabs[1].name }}
        </div>
      </div>

      <div *ngIf="sideTabs[0].active" class="mt-5">
        <div class="row form-inline justify-content-center">
          <img class="side-tab-image mr-3" src="{{ selectedFlight.image }}" />
          <div class="flight-type">{{selectedFlight.flightType}}</div>
        </div>

        <div class="row bold-flight-destination justify-content-center mt-5">JHB - PE</div>
        <div class="row form-inline align-content-center justify-content-between px-5">
          <div class="flex-column">
            <div class="flight-labels">Departure</div>
            <div class="label pt-2">{{ selectedFlight.departTime }}</div>
            <div class="flight-labels pt-1">{{ selectedFlight.departDate }}</div>
          </div>
          <div class="col">
            <div class="line"></div>
          </div>
          <div class="flex-column">
            <div class="flight-labels">Arrival</div>
            <div class="label pt-2">{{ selectedFlight.arrivalTime }}</div>
            <div class="flight-labels pt-1">{{ selectedFlight.arrivalDate }}</div>
          </div>
        </div>

        <div class="row bold-flight-destination justify-content-center mt-5">PE - CPT</div>
        <div class="row form-inline align-content-center justify-content-between px-5">
          <div class="flex-column">
            <div class="flight-labels">Departure</div>
            <div class="label pt-2">{{ selectedFlight.departTime }}</div>
            <div class="flight-labels pt-1">{{ selectedFlight.departDate }}</div>
          </div>
          <div class="col">
            <div class="line"></div>
          </div>
          <div class="flex-column">
            <div class="flight-labels">Arrival</div>
            <div class="label pt-2">{{ selectedFlight.arrivalTime }}</div>
            <div class="flight-labels pt-1">{{ selectedFlight.arrivalDate }}</div>
          </div>
        </div>
    
      </div>
      <div *ngIf="sideTabs[1].active"></div>
    </div>
  </div>
</div>
