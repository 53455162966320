<section class="mt-3">
  <div class="row form-inline align-items-center">
    <div class="col-sm-10">
      <span class="title">All Users</span>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-primary button" (click)="navigateUser()">
        <div class="row justify-content-center form-inline">
          <i class="fa fa-plus pr-2" aria-hidden="true"></i>
          <div class="button-text">ADD USER</div>
        </div>
      </button>
    </div>
  </div>

  <div class="row form-inline align-items-center border-top">
    <div class="col-sm-10">
      <span class="title">All Users</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table class="table table-striped table-borderless">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Employee No</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>
                <custom-dropdown
                  [options]="returnList"
                  [width]=""
                  [placeholder]="'Role'"
                  (returnSelectionChanged)="roleChanged($event)"
                >
                </custom-dropdown>
              </td>
              <td>
                <button type="button" class="btn btn-primary edit-button" (click)="navigateUser('5123412')">
                  <div class="row justify-content-center form-inline">
                    <div class="button-text pl-3 pr-3">EDIT</div>
                  </div>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
              <td>@fat</td>
              <td>
                <custom-dropdown
                  [options]="returnList"
                  [width]=""
                  [placeholder]="'Role'"
                  (returnSelectionChanged)="roleChanged($event)"
                >
                </custom-dropdown>
              </td>
              <td>
                <button type="button" class="btn btn-primary edit-button" (click)="navigateUser('5123412')">
                  <div class="row justify-content-center form-inline">
                    <div class="button-text pl-3 pr-3">EDIT</div>
                  </div>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
              <td>@twitter</td>
              <td>
                <custom-dropdown
                  [options]="returnList"
                  [width]=""
                  [placeholder]="'Role'"
                  (returnSelectionChanged)="roleChanged($event)"
                >
                </custom-dropdown>
              </td>
              <td>
                <button type="button" class="btn btn-primary edit-button">
                  <div class="row justify-content-center form-inline">
                    <div class="button-text pl-3 pr-3">EDIT</div>
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
