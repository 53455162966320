<nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <img class="logo-image mr-3" src="{{logo}}" (click)="home()"/>
      <ng-template ngFor let-item [ngForOf]="navItems" class="ms-5">
        <li
          class="nav-item px-2 form-inline"
          [ngClass]="{ active: getActiveRoute() === item.route }"
          (click)="setActiveTab(item.tab)"
        >
          <i class="fa {{ item.icon }} icon" aria-hidden="true"></i>
          <a class="text nav-link"> {{ item.name }}</a>
        </li>
      </ng-template>
    </ul>
    <div class="form-inline my-2 my-lg-0">
      <div class="mr-5 row d-flex align-items-center justify-content-center">
        <div class="mr-3 text-light" aria-label="Welcome" (click)="navigateUsers()">WELCOME !</div>
        <i class="fa fa-user-circle-o person-icon" aria-hidden="true"></i>
      </div>
      <div class="d-flex flex-column text-center">
        <i class="fa fa-sign-out icon" aria-hidden="true"></i>
        <div class="text-light">Log out</div>
      </div>
    </div>
  </div>
</nav>

<div
  class="card card-primary col-xs-12 col-md-12 no-background card-container vh-100 vw-100"
>
  <div class="card-body card-container p-0">
    <router-outlet></router-outlet>
  </div>
</div>
