import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { Options } from "src/app/interfaces/options";

@Component({
  selector: "app-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["./hotel.component.scss"]
})
export class HotelComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();
  public destinationList: Options[] = [{
    value: 'Durban Hotel'
  }, {value: 'Durban Hotel 2'}, {value: 'Durban Hotel 3'}];

  public flightOption: Options[] = [{
    value: 'Economy'
  }, {value: 'Economy +'}, {value: 'Business'}];

  public roomsList: Options[] = [
    {
      value: 'Primary Suite',
      quantity: 0,
    },
    {
      value: 'Standard Room',
      quantity: 0,
    },
    {
      value: 'Queen Room',
      quantity: 0,
    }
  ];

  public guestList: Options[] = [
    {
      value: 'Adult',
      subtitle: '(+16 yrs)',
      quantity: 0,
    },
    {
      value: 'Young Adult',
      subtitle: '(12-15 yrs)',
      quantity: 0,
    },
    {
      value: 'Children',
      subtitle: '(2-11 yrs)',
      quantity: 0,
    },
    {
      value: 'Infants',
      subtitle: '(under 2 yrs)',
      quantity: 0,
    }
  ];

  public radiusList: Options[] = [
    {value: '0 - 10km'}, {value: '11 - 30km'}, {value: '30 - 70km'}
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  returnChanged(selectedReturn: String) {
    console.log(selectedReturn);
  }

  viewHotelList() {
    this.router.navigate([AppRoutes.HOTEL_LIST]);
  }
}
