<div class="row hero">
  <div class="col-sm-12">
    <div class="section-container">
      <div class="row">
        <div class="col-sm-12">
          <status-stepper></status-stepper>
        </div>
      </div>
      <div class="mx-3 row d-flex">
        <div class="col-sm-12">
          <div class="header mt-4">Car Rental</div>
          <div class="subtitle mb-4 pt-1">
            What vehicle would you like to travel in?
          </div>
          <div class="input-group mb-3 checkbox-input">
            <div class="input-group-prepend">
              <div class="input-group-text bg-white">
                <input type="checkbox" />
              </div>
            </div>
            <input
              type="text"
              placeholder="Car remark"
              class="form-control input-text"
              aria-label="Car remark"
            />
          </div>
          <div class="row d-flex mt-4">
            <div class="col-sm-6">
              <div class="title mb-2">Pick up Location</div>
              <custom-input
                [options]="pikcupList"
                [placeholder]="pikcupList[0].value"
                [width]="'100%'"
                [icon]="'fa-map-marker'"
              ></custom-input>
            </div>
          </div>
          <div class="input-group checkbox-input mt-4">
            <div class="input-group-prepend">
              <div class="input-group-text bg-white">
                <input type="checkbox" />
              </div>
            </div>
            <input
              type="text"
              placeholder="Drop car off at different location."
              class="form-control input-text"
              aria-label="Drop car off"
            />
          </div>
        </div>
      </div>
      <div class="row d-flex mt-4 mx-3">
        <div class="col-sm-4">
          <div class="title mb-2">Pick up Date</div>
          <date-picker></date-picker>
        </div>
        <div class="col-sm-2">
          <div class="title mb-2">Time</div>
          <custom-dropdown
            [options]="timeList"
            [width]="'100%'"
            [placeholder]="timeList[0].value"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
        <div class="col-sm-4">
          <div class="title mb-2">Drop off Date</div>
          <date-picker></date-picker>
        </div>
        <div class="col-sm-2">
          <div class="title mb-2">Time</div>
          <custom-dropdown
            [options]="timeList"
            [width]="'100%'"
            [placeholder]="timeList[0].value"
            (returnSelectionChanged)="returnChanged($event)"
          >
          </custom-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 my-5 mx-4 flex-column">
          <div class="btn-group-vertical">
            <button
              type="button"
              class="btn btn-primary search-button"
              (click)="viewCarList()"
            >
              Search Rentals
              <i class="fa fa-long-arrow-right icon" aria-hidden="true"></i>
            </button>

            <button
              type="button"
              class="btn btn-outline-primary skip-button mt-3"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
