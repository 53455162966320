import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { Options } from 'src/app/interfaces/options';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();

  public returnList: Options[] = [
    {
      value: 'Approver',
    },
    { value: 'Arranger' },
    { value: 'Traveller' },
  ];

  constructor(private router: Router) {}

  ngOnInit() {}

  roleChanged(role: String) {
    console.log(role);
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  navigateUser(user?: String) {
    user
      ? this.router.navigate([AppRoutes.USER, { id: user }])
      : this.router.navigate([AppRoutes.USER]);
  }
}
