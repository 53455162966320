import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Options } from 'src/app/interfaces/options';

@Component({
  selector: 'custom-dropdown',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {
  public dropdownForm: NgForm;
  public isDropDownOpen: boolean = false;
  public dropdown: string = '';
  @Input('options') options: Options[];
  @Input('width') width: string;
  @Input('placeholder') placeholder: string;
  @Input('adjustable') adjustable: boolean = false;
  @Input('icon') icon: string = '';
  @Output() returnSelectionChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.options.forEach((opt) => {
      opt.isActive = false;
    });
    this.dropdown = this.placeholder;
    //this.options.splice(0, 0, { value: '', isActive: true });
  }

  toggleDropdown() {
    this.isDropDownOpen = !this.isDropDownOpen;
  }

  closeDropdown() {
    this.isDropDownOpen = false;
  }

  selectOption(evt: any, optionIndex: number) {
    if (!this.adjustable) {
      this.toggleDropdown();
      this.options.forEach((opt: any, index: number) => {
        opt.isActive = optionIndex === index;
        opt.isActive && this.returnSelectionChanged.emit(opt);
      });
      this.dropdown = evt.target.innerHTML;
    } else {
      let passangerText = '';
      let kidsQuantity = 0;
      let adultsQuantity = 0;
      this.options.forEach((opt: any) => {
        if(opt.quantity > 0) {
          if (opt.value !== 'Children' && opt.value !== 'Infants') {
            adultsQuantity = adultsQuantity + opt.quantity;
          } else {
            kidsQuantity = kidsQuantity + opt.quantity;
          }
        }
      })
      passangerText = adultsQuantity + ' Adults, ' + kidsQuantity + ' Children'
      this.dropdown = passangerText.length > 0 ? passangerText : '0 Adults'
    }
  }

  adjustQuantity(evt: any, optionIndex: number, adjustType: string) {
    const shouldIncrease = adjustType === 'increase';
    this.options.forEach((opt: any, index: number) => {
      if (optionIndex === index) {
        opt.quantity = shouldIncrease ? opt.quantity + 1 : opt.quantity - 1; 
      }
    });
    this.returnSelectionChanged.emit(this.options)
    this.dropdown = evt.target.innerHTML;
  }

}
