import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { RootStoreState } from 'src/app/store';
import { AppRoutes } from 'src/app/shared/endpoints.enum';
import { TitleOptions } from 'src/app/interfaces/title-options';
import { Options } from 'src/app/interfaces/options';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  private cancelationSubscription = new Subscription();
  newUser: boolean = false;
  public titleOptions: Options[] = [
    {
      value: 'Mr',
    },
    { value: 'Ms' },
  ];

  public genderOptions: Options[] = [
    {
      value: 'Male',
    },
    { value: 'Female' },
  ];

  public nationality: Options[] = [
    {
      value: 'South African',
    },
    { value: 'Other' },
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id')) {
      this.newUser = false;
    } else {
      this.newUser = true;
    }
  }

  ngOnDestroy() {
    this.cancelationSubscription.unsubscribe();
  }

  goBack() {
    this.router.navigate([AppRoutes.USER_LIST]);
  }

  dropdownChanged(event: String) {
  }
}
