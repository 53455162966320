import { Component, inject } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
    selector: 'date-picker-range',
    templateUrl: './date-picker-range.component.html',
    styleUrls: ['./date-picker-range.component.scss']
})
export class DatePickerRangeComponent {
    bsConfig: Partial<BsDatepickerConfig>;
    bsInlineValue = new Date();
    bsValue = new Date();
    bsRangeValue: Date[];
    maxDate = new Date();
    minDate = new Date();

    constructor() {
        this.minDate.setDate(this.minDate.getDate() - 1);
        this.maxDate.setDate(this.maxDate.getDate() + 7);
        this.bsRangeValue = [this.bsValue, this.maxDate];
    }
}